import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "absolute",
  background: 'linear-gradient(#0072ac, #004872)'
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
}));

const CreateUserView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "park://createuser?createUserToken=" + params.createUserToken;
  }, [])

  return (
    <Root>
      <Wrapper>
        <ContentContainer>
          <Content>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container
                maxWidth="sm"
              >
                <Box mb={4} marginBottom="70px">
                  <img
                    alt="Logo"
                    src="/logo.svg"
                  />
                </Box>
              </Container>
            </Box>
          </Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
}

export default CreateUserView;