import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import React from 'react';

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  position: "absolute",
  background: 'linear-gradient(#0072ac, #004872)'
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
}));

const MainView: React.FC = () => {
  return (
    <Root>
      <Wrapper>
        <ContentContainer>
          <Content>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container
                maxWidth="sm"
              >
                <Box mb={4} marginBottom="70px">
                  <img
                    alt="Logo"
                    src="/logo.svg"
                  />
                </Box>
                <a href='https://apps.apple.com/us/app/parkör/id6448720047'>
                  <Box
                    mb={4}
                    display="flex"
                    justifyContent="center"
                    marginBottom={"40px"}
                  >
                    <img
                      width={"85%"}
                      alt="App Store"
                      src="/appstore.svg"
                    />

                  </Box>
                </a>
                <a href='https://play.google.com/store/apps/details?id=net.parkor.mobile&pli=1'>
                  <Box
                    mb={4}
                    display="flex"
                    justifyContent="center"
                    marginBottom={"40px"}
                  >
                    <img
                      width={"85%"}
                      alt="Play Store"
                      src="/playstore.svg"
                    />

                  </Box>
                </a>
              </Container>
            </Box>
          </Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
}

export default MainView;