import { Navigate } from "react-router-dom";
import MainView from "view/main.view";
import ResetPasswordView from "view/resetpassword.view";
import CreateUserView from "../view/createuser.view";

const MainRoute = () => [
  {
    path: "/createuser/:createUserToken",
    element: <CreateUserView />
  },
  {
    path: "/resetpassword/:resetPasswordToken",
    element: <ResetPasswordView />
  },
  {
    path: "/",
    element: <MainView />
  }
];

export default MainRoute;