import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import mainRoute from 'route/main.route';

const App: React.FC = () => {
  const routing = useRoutes(mainRoute());

  return (
    <>
      {routing}
    </>
  );
}

export default App;